/* .custom-search {
    display: flex;
    align-items: center;
}

.custom-search-input {
    width: 300px;
    padding: 8px; 
    
    border: 1px solid #ccc;
    border-radius: 4px;
} */

.MuiDataGrid-filterForm {
  position: absolute !important;
  z-index: 1;
  top: -160px;
  left: 0;
  background-color: white;
}

.datagrid-container {
  position: relative;
}

.MuiDataGrid-root {
  height: 510px !important;
  overflow: hidden;
}

.row-data {
  position: absolute;
  width: calc(100% - 2px);
  right: 1px;
  top: 1px;
  bottom: 0;
  height: 40px;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-bottom: 1px solid #e0e0e0;
}

.cursor-pointer {
  cursor: pointer;
}

.dark .row-data {
  background-color: #2b2e3b;
  color: #fff;
}

.dark .MuiDataGrid-columnHeader,
.dark .MuiDataGrid-cell,
.dark .MuiToolbar-root,
.dark .MuiDataGrid-selectedRowCount {
  color: #fff;
}

.searchContainer {
  display: flex;
  justify-content: end;
  margin-top: 5px;
}

.searchContainer .searchBar {
  border: none;
  outline: none;
  padding: 5px 15px;
  border-radius: 5px;
  margin-right: 2px;
  background-color: #dbd6d4;
}

.searchContainer .cancelSearch {
  color: #c4b4ae;
  cursor: pointer;
}

.font-Icon-Del {
  color: rgb(236, 26, 26);
}

.font-Icon-Up {
  color: rgb(75, 75, 161);
}

.font-Icon-view {
  color: rgb(79, 79, 199);
  font-size: 20px;
}

/* .time-picker-container {
  margin-bottom: 25px;
}

.custom-time-picker {
  font-size: 20px;
  padding: 25px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 300px;
} */

.table-heading {
  font-size: 20px;
  /* You can adjust the font size as needed */
  font-weight: bold;
  /* background-color: #645f5c; */
  /* padding: 2px 2px; */
  /* text-align: center; */
  /* color: #ffffffc2; */
  /* border-left: 8px solid #968c87; */
  /* border-radius: 2px; */
  /* border-top-right-radius: 5px; */
  /* border-bottom-right-radius: 5px; */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
}

.settingsContainer {
  border-bottom: 1px solid #5b73e8;
}

.settingsContainer .activateSettings {
  background-color: #aab3e4;
  /* border-radius: 5px; */
  border-bottom: 5px solid #5b73e8;
}

.custom-no-rows-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 18px;
  color: gray;
}


.dropdown-item {
  cursor: pointer !important;
}

.imageSize {
  color: red;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  z-index: 20 !important;
}

.disableColor {
  background-color: lightgrey !important;
}

.appmap {
  height: 450px;
  width: 100%;
}

.sidebar-menu-scroll {
  height: 580px;
  /* overflow-y: auto; */
}

.dark table {
  border: 2px solid black;
}


.dark th {
  background-color: #333 !important;
  /* Choose your desired background color */
  color: white !important;
  /* Choose your desired text color */
}


.dark td {
  background-color: rgb(240, 240, 240) !important;
  color: black !important;
}


.MuiFormLabel-root {
  z-index: 0 !important;
}